<template>
  <hr class="separator " style="margin-top: 1px"/>

  <PluginBox
      v-if="pluginStatus !== 0"
      :visiable="pluginStatus !== 0"
      :pluginStatus="pluginStatus"
      :pluginUrl="pluginUrl"
      :handleClose="handlePluginBoxClose"
  ></PluginBox>

  <div>
    <HeaderTop/>
    <div class="container">
      <DiagnosisBox :motorInfo="motorInfo" :motorConfigData="motorConfigData"
                    :dashboardInfo="data.dashboardData" :motorTypeAndModelData="motorTypeAndModelData"
                    :batteryInfo="batteryInfo" :batteryDetailData="batteryDetailData"
                    :displayInfo="displayInfo" :displayConfigData="displayConfigData"
                    :displayInfoRealTimeData="displayInfoRealTimeData"
                    :displayTypeAndModelData="displayTypeAndModelData"
      />
    </div>

    <DiagnosisTabs :motorInfo="motorInfo" :motorTypeAndModelData="motorTypeAndModelData"
                   :motorSelfErrorData="motorSelfErrorData"
                   :motorHistoryError="motorHistoryError"
                   :motorInfoRealTimeData="motorInfoRealTimeData"
                   :motorConfigData="motorConfigData"
                   :batteryInfo="batteryInfo"
                   :batteryDetailData="batteryDetailData"
                   :displayInfo="displayInfo"
                   :displayTypeAndModelData="displayTypeAndModelData"
    />
  </div>
</template>

<script>
import HeaderTop from "./components/HeaderTop.vue";
import DiagnosisBox from "./components/DiagnosisBox.vue";
import DiagnosisTabs from "./components/DiagnosisTabs.vue";
import {pluginStatusMixin} from "@/mixins/Mixin";
import {mapActions, mapMutations, mapState} from "vuex";
import {getProductModels} from "@/api/api";
import MyMixin from "@/mixins/Mixin";
import EventBus from "@/eventBus";
import PluginBox from '@/components/PluginBox.vue';

export default {
  components: {
    PluginBox,
    HeaderTop,
    DiagnosisBox,
    DiagnosisTabs,
  },
  data() {
    return {
      data: {},
      motorTypeAndModelData: {},
      displayTypeAndModelData: {},
    };
  },

  created() {
    this.loadData();
  },
  mixins: [pluginStatusMixin, MyMixin],
  mounted() {
    EventBus.on('reconnect', this.resetMotorTypeAndModelData);
    // 页面加载时调用检测插件状态的方法
    this.checkPluginStatus();
    // 将 motorConfigData 字符串转换为 JSON 对象
    if (this.motorConfigData && typeof this.motorConfigData === 'string') {
      try {
        this.setMotorConfigData(JSON.parse(this.motorConfigData));
      } catch (error) {
        console.error('Error parsing motorConfigData:', error);
      }
    }
    // 将 motorInfo 字符串转换为 JSON 对象
    if (this.motorInfo && typeof this.motorInfo === 'string') {
      try {
        this.setMotorInfo(JSON.parse(this.motorInfo));
      } catch (error) {
        console.error('Error parsing motorInfo:', error);
      }
    }

  },
  beforeUnmount() {
    EventBus.emit('isTesting', false);
    EventBus.off('reconnect', this.resetMotorTypeAndModelData); // 在组件卸载前移除监听器

  },
  watch: {
    motorConfigData(newValue) {
      // 检查newValue的类型，适当处理
      if (typeof newValue === 'string') {
        this.setMotorConfigData(JSON.parse(newValue));
      }
    },
    motorInfo(newValue) {
      // 检查newValue的类型，适当处理
      if (typeof newValue === 'string') {
        const newInfo = JSON.parse(newValue);
        this.setMotorInfo(newInfo);
        if (newInfo.hardwareversion) {
          // 现在可以安全地使用 hardwareversion
          this.getMotorTypeAndModel();
        }
      }
    },

    batteryInfo(newValue) {
      // 检查newValue的类型，适当处理
      if (typeof newValue === 'string') {
        this.setBatteryInfo(JSON.parse(newValue));
      }
    },
    batteryDetailData(newValue) {
      // 检查newValue的类型，适当处理
      if (typeof newValue === 'string') {
        this.setBatteryDetailData(JSON.parse(newValue));
      }
    },
    displayInfo(newValue) {
      // 检查newValue的类型，适当处理
      if (typeof newValue === 'string') {
        const newInfo = JSON.parse(newValue);
        this.setDisplayInfo(newInfo);
        if (newInfo.hardwareversion) {
          // 现在可以安全地使用 hardwareversion
          this.getDisplayTypeAndModel();
        }
      }
    },
    displayConfigData(newValue) {
      // 检查newValue的类型，适当处理
      if (typeof newValue === 'string') {
        this.setDisplayConfigData(JSON.parse(newValue));
      }
    },
    displayInfoRealTimeData(newValue) {
      // 检查newValue的类型，适当处理
      if (typeof newValue === 'string') {
        this.setDisplayRealTimeData(JSON.parse(newValue));
      }
    },

    motorHistoryError(newValue) {
      // 检查newValue的类型，适当处理
      if (typeof newValue === 'string') {
        try {
          const parsedValue = JSON.parse(newValue);

          // 确保parsedValue是一个数组
          if (Array.isArray(parsedValue)) {
            // 调用方法，并将解析后的JSON数组作为参数传递
            this.getMotorInfoHistoryErrorData(parsedValue);
          } else {
            console.error('Parsed value is not an array:', parsedValue);
          }

        } catch (error) {
          console.error('Error parsing motorHistoryError:', error);
        }
      }
    },

  },

  computed: {
    ...mapState(['motorInfo']), // 从 Vuex store 中映射 motorInfo 到组件的 computed 属性
    ...mapState(['motorConfigData']), // 从 Vuex store 中映射 motorInfo 到组件的 computed 属性
    ...mapState(['motorConfigData1']), // 从 Vuex store 中映射 motorInfo 到组件的 computed 属性
    ...mapState(['motorInfoRealTimeData']), // 从 Vuex store 中映射 motorInfo 到组件的 computed 属性

    ...mapState(['batteryInfo']), // 从 Vuex store 中映射 motorInfo 到组件的 computed 属性
    ...mapState(['batteryDetailData']), // 从 Vuex store 中映射 motorInfo 到组件的 computed 属性

    ...mapState(['displayInfo']), // 从 Vuex store 中映射 motorInfo 到组件的 computed 属性
    ...mapState(['displayConfigData']), // 从 Vuex store 中映射 motorInfo 到组件的 computed 属性
    ...mapState(['displayInfoRealTimeData']), // 从 Vuex store 中映射 motorInfo 到组件的 computed 属性

    ...mapState(['motorSelfErrorData']), // 从 Vuex store 中映射 motorInfo 到组件的 computed 属性
    ...mapState(['motorHistoryError']), // 从 Vuex store 中映射 motorInfo 到组件的 computed 属性

    ...mapState(['isMotorConnected']),
    ...mapState(['pluginStatus']),
    ...mapState(['pluginUrl']),
  },
  methods: {
    ...mapMutations(['setMotorInfo']), // 将 Vuex store 中的 setMotorInfo mutation 映射到组件的 methods 中
    ...mapMutations(['setMotorConfigData']), // 将 Vuex store 中的 setMotorInfo mutation 映射到组件的 methods 中
    ...mapMutations(['setMotorConfigData1']), // 将 Vuex store 中的 setMotorInfo mutation 映射到组件的 methods 中
    ...mapMutations(['setMotorRealTimeData']), // 将 Vuex store 中的 setMotorInfo mutation 映射到组件的 methods 中

    ...mapMutations(['setBatteryInfo']), // 将 Vuex store 中的 setMotorInfo mutation 映射到组件的 methods 中
    ...mapMutations(['setBatteryDetailData']), // 将 Vuex store 中的 setMotorInfo mutation 映射到组件的 methods 中

    ...mapMutations(['setDisplayInfo']), // 将 Vuex store 中的 setMotorInfo mutation 映射到组件的 methods 中
    ...mapMutations(['setDisplayConfigData']), // 将 Vuex store 中的 setMotorInfo mutation 映射到组件的 methods 中
    ...mapMutations(['setDisplayRealTimeData']), // 将 Vuex store 中的 setMotorInfo mutation 映射到组件的 methods 中

    ...mapActions([
      'getMotorSelfErrorData', // 将 Vuex store 中的 getMotorSelfErrorData action 映射到组件的 methods 中
    ]),
    ...mapActions([
      'getMotorInfoHistoryErrorData', // 将 Vuex store 中的 getMotorSelfErrorData action 映射到组件的 methods 中
    ]),
    ...mapMutations(['setMotorConnected']),
    ...mapActions(['fetchPluginVersion']), // 映射 Vuex actions
    ...mapActions(['checkPluginVersionAndUpdate']),
    handlePluginBoxClose() {
      this.$store.commit('setPluginStatus', 0);
    },

    resetMotorTypeAndModelData() {
      this.motorTypeAndModelData = {};  // 重置型号数据
    },

    loadData() {
      this.data = {
        motorData: {
          motorModel: "",
          motorSerialNumber: "",
          motorSoftwareVersion: "",
          motorHardwareVersion: "",

          mileage: "",
          wheelSize: "",
          maxGear: "",

          currentLevel1: "",
          currentLevel2: "",
          currentLevel3: "",
          currentLevel4: "",
          currentLevel5: "",
          currentLevel6: "",
          currentLevel7: "",
          currentLevel8: "",
          currentLevel9: "",

          maxSpeed1: "",
          maxSpeed2: "",
          maxSpeed3: "",
          maxSpeed4: "",
          maxSpeed5: "",
          maxSpeed6: "",
          maxSpeed7: "",
          maxSpeed8: "",
          maxSpeed9: "",
        },
        batteryData: {
          batteryModel: "",
          batterySerialNumber: "",
          batterySoftwareVersion: "",
          batteryHardwareVersion: "",

          voltage: "",
          current: "",
          capacity: "",
          cycleCount: "",
          soc: "",
          remainingCapacity: "",
          healthPercentage: "",
        },
        dashboardData: {
          dashboardModel: "",
          dashboardSoftwareVersion: "",
          dashboardSerialNumber: "",
          dashboardHardwareVersion: "",
        },
      };
    },
    async getMotorTypeAndModel() {
      if (!this.isMotorConnected) {
        // 如果电机不是连接状态，直接设置为空对象
        this.motorTypeAndModelData = {};
        return; // 提前退出方法
      }
      try {
        let searchParams = {
          "hardware_version-eq": this.motorInfo?.hardwareversion,
        };
        const response = await getProductModels(1, 10, searchParams);
        if (response.data && response.data.length > 0) {
          this.motorTypeAndModelData = {
            motor_id: response.data[0].id,
            hardwareVersion: response.data[0].hardware_version,
            model: response.data[0].code,
            motorType: this.mapMotorTypeToString(response.data[0]?.motor_type),
          };
          EventBus.emit('MotorTypeAndModelDataMessage', this.motorTypeAndModelData);
        } else {
          // 处理错误或空数据的情况
          console.error('No data available');
        }

      } catch (error) {
        console.error('Error fetching motor type and model:', error);
        // 这里可以处理错误，例如设置错误消息或执行回退逻辑
      }
    },
    async getDisplayTypeAndModel() {
      try {
        let searchParams = {
          "hardware_version-eq": this.displayInfo?.hardwareversion,
        };
        const response = await getProductModels(1, 10, searchParams);
        if (response.data && response.data.length > 0) {
          this.displayTypeAndModelData = {
            hardwareVersion: response.data[0].hardware_version,
            model: response.data[0].code,
          };
        } else {
          // 处理错误或空数据的情况
          console.error('No data available');
        }

      } catch (error) {
        console.error('Error fetching motor type and model:', error);
        // 这里可以处理错误，例如设置错误消息或执行回退逻辑
      }
    },

  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-top: 30px;
}
</style>
  