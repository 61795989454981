<template>
  <div class="diagnosis-box">

    <div class="content-wrapper">
      <img :src="motorImgUrl" width="40" alt="motor" height="40"/>
      <span class="motor-font">
  {{
          getMotorTypeAndModelData.motorType === 'mid' ? $t('ProductDiagnosis.centerMotor') :
              getMotorTypeAndModelData.motorType === 'hub' ? $t('ProductDiagnosis.wheelMotor') :
                  $t('ProductDiagnosis.motor')
        }}
</span>

      <span class="status" :class="{ online: isMotorConnected && !isMotorConnecting }" :style="motorStatusStyle">
        <template v-if="isMotorConnecting">
           {{ $t('ProductDiagnosis.connecting') }}<span class="blinking-dots"><span>.</span><span>.</span><span>.</span></span>
        </template>
        <template v-else-if="isMotorConnected">
          {{ $t('ProductUpgrade.online') }}
        </template>
        <template v-else>
          {{ $t('ProductUpgrade.offline') }}
        </template>

      </span>

      <div class="tooltip-wrapper" v-if="!isMotorConnected && !isMotorConnecting">

        <el-tooltip class="box-item" effect="light" placement="right">
          <template v-slot:content>
            <div class="font-setting-top">{{ $t('ProductDiagnosis.checkConnection') }}</div>
            <div class="font-setting-bottom">{{ $t('ProductDiagnosis.checkBatteryPower') }}</div>
          </template>
          <el-icon class="invert-icon">
            <QuestionIcon/>
          </el-icon>

        </el-tooltip>
      </div>

    </div>
    <div class="info">
      <table class="table-style">
        <tr>
          <td class="td-style">
            <span>{{ $t('ProductDiagnosis.model') }}</span>
            <span class="custom-color">{{ getMotorTypeAndModelData.model || '-' }}</span>
          </td>
          <td class="td-spacing"></td> <!-- 空的间隔单元格 -->
          <td class="td-style">
            <span>{{ $t('ProductDiagnosis.firmwareVersion') }}</span>
            <span class="custom-color">{{ motorInfo?.softwareversion || '-' }}</span>
          </td>

          <td class="td-button"></td>
        </tr>
        <tr>
          <td class="td-style">
            <span>{{ $t('ProductDiagnosis.serialNumber') }}</span>
            <span class="custom-color">{{ motorInfo?.sncode || '-' }}</span>
          </td>
          <td class="td-spacing"></td> <!-- 空的间隔单元格 -->
          <td class="td-style">
            <span>{{ $t('ProductDiagnosis.hardwareVersion') }}</span>
            <span class="custom-color">{{ motorInfo?.hardwareversion || '-' }}</span>
          </td>

          <td class="td-button"><a href="#" @click="showDetails" class="details-link">{{
              $t('ProductDiagnosis.detail')
            }}</a></td>
        </tr>
      </table>
    </div>

    <el-dialog v-model="motorVisible" width="900" style="font-size: 14px;" append-to-body >
      <template #header>
        <div class="my-header">
          {{ $t('ProductDiagnosis.motorDetail') }}
        </div>

        <hr/>

        <!-- 第一块内容：电机基本信息 -->
        <div class="content-wrapper">
          <img :src="motorImgUrl" width="40" height="40" alt="motor"/>
          <span class="motor-font"> {{
              getMotorTypeAndModelData.motorType === 'mid' ? $t('ProductDiagnosis.centerMotor') :
                  getMotorTypeAndModelData.motorType === 'hub' ? $t('ProductDiagnosis.wheelMotor') :
                      $t('ProductDiagnosis.motor')
            }}</span>

          <span class="status" :class="{ online: isMotorConnected }" :style="motorStatusStyle">{{
              isMotorConnected ? $t('ProductDiagnosis.online') : $t('ProductDiagnosis.offline')
            }}</span>
        </div>
        <div class="flex-container">
          <div class="flex-item">
            <p>{{ $t('ProductDiagnosis.model') }} <span class="custom-color">{{
                getMotorTypeAndModelData.model || '-'
              }}</span>
            </p>
          </div>
          <div class="flex-item">
            <p>{{ $t('ProductDiagnosis.serialNumber') }} <span
                class="custom-color">{{ motorInfo?.sncode || '-' }}</span></p>
          </div>
          <div class="flex-item">
            <p>{{ $t('ProductDiagnosis.firmwareVersion') }} <span
                class="custom-color">{{ motorInfo?.softwareversion || '-' }}</span></p>
          </div>
          <div class="flex-item">
            <p>{{ $t('ProductDiagnosis.hardwareVersion') }} <span
                class="custom-color">{{ motorInfo?.hardwareversion || '-' }}</span></p>
          </div>
        </div>
        <div class="section">
          <hr/>
          <div class="flex-container">
            <div class="flex-item">
              <p> {{ $t('ProductDiagnosis.odo') }} <span class="custom-color">{{
                  motorConfigData?.totalkilometer || '-'
                }} km</span>
              </p>
              <p> {{ $t('ProductDiagnosis.circumference') }} <span class="custom-color">{{
                  motorConfigData?.wheelCircumference || '-'
                }} mm</span></p>
              <p> {{ $t('ProductDiagnosis.maximumLevel') }} <span class="custom-color">{{
                  motorConfigData?.maxLevel || '-'
                }}</span></p>
            </div>
            <div class="flex-item" v-if="displayedLevels && displayedLevels.length > 0">
              <template v-for="(level,index) in displayedLevels" :key="'currentLevel' + index">
                <p>{{ $t('ProductDiagnosis.currentLevel') }}{{ index + 1 }} <span
                    class="custom-color">{{ level.currentLevel }} A</span></p>
              </template>
            </div>

            <div class="flex-item" v-else>
              <p> {{ $t('ProductDiagnosis.currentLevel') }}1 <span
                  class="custom-color">{{ motorConfigData?.Current_Level_1 || '-' }} A</span></p>
              <p> {{ $t('ProductDiagnosis.currentLevel') }}2 <span
                  class="custom-color">{{ motorConfigData?.Current_Level_2 || '-' }} A</span></p>
              <p> {{ $t('ProductDiagnosis.currentLevel') }}3 <span
                  class="custom-color">{{ motorConfigData?.Current_Level_3 || '-' }} A</span></p>
              <p> {{ $t('ProductDiagnosis.currentLevel') }}4 <span
                  class="custom-color">{{ motorConfigData?.Current_Level_4 || '-' }} A</span></p>
              <p> {{ $t('ProductDiagnosis.currentLevel') }}5 <span
                  class="custom-color">{{ motorConfigData?.Current_Level_5 || '-' }} A</span></p>
              <p>{{ $t('ProductDiagnosis.currentLevel') }}6 <span
                  class="custom-color">{{ motorConfigData?.Current_Level_6 || '-' }} A</span></p>
              <p>{{ $t('ProductDiagnosis.currentLevel') }}7 <span
                  class="custom-color">{{ motorConfigData?.Current_Level_7 || '-' }} A</span></p>
              <p>{{ $t('ProductDiagnosis.currentLevel') }}8 <span
                  class="custom-color">{{ motorConfigData?.Current_Level_8 || '-' }} A</span></p>
              <p>{{ $t('ProductDiagnosis.currentLevel') }}9 <span
                  class="custom-color">{{ motorConfigData?.Current_Level_9 || '-' }} A</span></p>
            </div>

            <div class="flex-item" v-if="displayedLevels && displayedLevels.length > 0">
              <template v-for="(level,index) in displayedLevels" :key="'speedLevel' + index">
                <p> {{ index + 1 }}{{ $t('ProductDiagnosis.level') }} <span
                    class="custom-color">{{ level.speedLevel }} km/h</span></p>
              </template>

            </div>

            <div class="flex-item" v-else>
              <p> {{ $t('ProductDiagnosis.level1') }} <span
                  class="custom-color">{{ motorConfigData?.Bike_speed_Level_1 || '-' }} km/h</span></p>
              <p> {{ $t('ProductDiagnosis.level2') }} <span
                  class="custom-color">{{ motorConfigData?.Bike_speed_Level_2 || '-' }} km/h</span></p>
              <p> {{ $t('ProductDiagnosis.level3') }} <span
                  class="custom-color">{{ motorConfigData?.Bike_speed_Level_3 || '-' }} km/h</span></p>
              <p>{{ $t('ProductDiagnosis.level4') }} <span
                  class="custom-color">{{ motorConfigData?.Bike_speed_Level_4 || '-' }} km/h</span></p>
              <p> {{ $t('ProductDiagnosis.level5') }} <span
                  class="custom-color">{{ motorConfigData?.Bike_speed_Level_5 || '-' }} km/h</span></p>
              <p> {{ $t('ProductDiagnosis.level6') }} <span
                  class="custom-color">{{ motorConfigData?.Bike_speed_Level_6 || '-' }} km/h</span></p>
              <p> {{ $t('ProductDiagnosis.level7') }} <span
                  class="custom-color">{{ motorConfigData?.Bike_speed_Level_7 || '-' }} km/h</span></p>
              <p> {{ $t('ProductDiagnosis.level8') }} <span
                  class="custom-color">{{ motorConfigData?.Bike_speed_Level_8 || '-' }} km/h</span></p>
              <p> {{ $t('ProductDiagnosis.level9') }} <span
                  class="custom-color">{{ motorConfigData?.Bike_speed_Level_9 || '-' }} km/h</span></p>
            </div>

          </div>
        </div>

        <hr/>
        <div v-if="motorConfigData?.externaldevices">
          <div style="text-align: left">{{ $t('ProductDiagnosis.externalDevices') }}</div>
          <ul class="external-devices-list">
            <li v-for="(device,index) in motorConfigData?.externaldevices" :key="device">
              {{ deviceName(device) }}
              <template v-if="index !== motorConfigData?.externaldevices.length - 1">,</template>
            </li>
          </ul>
        </div>

      </template>
    </el-dialog>
  </div>

  <div class="diagnosis-box">
    <div class="content-wrapper">
      <img :src="batteryImgUrl" width="40" height="40" alt="battery"/>
      <span class="motor-font"> {{ $t('ProductDiagnosis.battery') }}</span>
      <span class="status" :class="{ online: isBatteryInfoEmpty && !isBatteryConnecting }" :style="batteryStatusStyle">

        <template v-if="isMotorConnecting || isBatteryConnecting">
           {{ $t('ProductDiagnosis.connecting') }}<span class="blinking-dots"><span>.</span><span>.</span><span>.</span></span>
        </template>
        <template v-else-if="!isBatteryConnecting && !isBatteryInfoEmpty">
          {{ $t('ProductDiagnosis.online') }}
        </template>
        <template v-else>
          {{ $t('ProductDiagnosis.offline') }}
        </template>
      </span>

      <div class="tooltip-wrapper" v-if="isBatteryInfoEmpty && !isBatteryConnecting && !isMotorConnecting">
        <el-tooltip class="box-item" effect="light" placement="right">
          <template v-slot:content>
            <div class="font-setting-top">{{ $t('ProductDiagnosis.checkConnection') }}</div>
            <div class="font-setting-bottom">{{ $t('ProductDiagnosis.checkBatteryPower') }}</div>
          </template>
          <el-icon class="invert-icon">
            <QuestionIcon/>
          </el-icon>
        </el-tooltip>
      </div>

    </div>
    <div class="info">
      <table class="table-style">
        <tr>
          <td class="td-style">
            {{ $t('ProductDiagnosis.model') }}
            <span class="custom-color"> {{ batteryInfo?.hardwareversion || '-' }}</span>
          </td>
          <td class="td-spacing"></td> <!-- 空的间隔单元格 -->
          <td class="td-style">
            {{ $t('ProductDiagnosis.firmwareVersion') }}
            <span class="custom-color">{{ batteryInfo?.firmwareversion || '-' }}</span>
          </td>
          <td class="td-button"></td>
        </tr>
        <tr>
          <td class="td-style">
            {{ $t('ProductDiagnosis.serialNumber') }}
            <span class="custom-color">{{ batteryInfo?.sncode || '-' }}</span>
          </td>
          <td class="td-spacing"></td> <!-- 空的间隔单元格 -->
          <td class="td-style">
            {{ $t('ProductDiagnosis.hardwareVersion') }}
            <span class="custom-color">{{ batteryInfo?.hardwareversion || '-' }}</span>
          </td>
          <td class="td-button"><a href="#" @click="showBatteryDetails" class="details-link">
            {{ $t('ProductDiagnosis.detail') }}</a></td>
        </tr>
      </table>
    </div>

    <el-dialog v-model="batteryVisible" width="900" append-to-body>
      <template #header>
        <div class="my-header">
          {{ $t('ProductDiagnosis.batteryDetail') }}
        </div>

        <hr/>

        <!-- 第一块内容：电池基本信息 -->
        <div class="content-wrapper">

          <img :src="batteryImgUrl" width="40" height="40" alt="batteryImg"/>
          <span class="motor-font"> {{ $t('ProductDiagnosis.battery') }}</span>
          <span class="status" :class="{ online: isBatteryInfoEmpty }" :style="batteryStatusStyle">
              {{ !isBatteryInfoEmpty ? $t('ProductDiagnosis.online') : $t('ProductDiagnosis.offline') }}
          </span>
        </div>

        <div class="flex-container">
          <div class="flex-item">
            <p> {{ $t('ProductDiagnosis.model') }} <span class="custom-color">{{
                batteryInfo?.hardwareversion || '-'
              }}</span></p>
          </div>
          <div class="flex-item">
            <p> {{ $t('ProductDiagnosis.serialNumber') }} <span
                class="custom-color">{{ batteryInfo?.sncode || '-' }}</span></p>
          </div>
          <div class="flex-item">
            <p> {{ $t('ProductDiagnosis.firmwareVersion') }} <span
                class="custom-color">{{ batteryInfo?.firmwareversion || '-' }}</span></p>
          </div>
          <div class="flex-item">
            <p> {{ $t('ProductDiagnosis.hardwareVersion') }} <span
                class="custom-color">{{ batteryInfo?.hardwareversion || '-' }}</span></p>
          </div>
        </div>
        <div class="section">
          <hr/>
          <div class="battery-flex-container">
            <div class="battery-flex-item">
              <p> {{ $t('ProductDiagnosis.voltage') }} <span class="custom-color">{{
                  batteryInfo?.BatVoltage || '-'
                }} V</span></p>
            </div>
            <div class="battery-flex-item">
              <p> {{ $t('ProductDiagnosis.current') }} <span class="custom-color">{{
                  batteryInfo?.BatRealTimeCur || '-'
                }} mA</span></p>
            </div>
            <div class="battery-flex-item">
              <p> {{ $t('ProductDiagnosis.fullCapacity') }} <span class="custom-color">{{
                  batteryInfo?.BatFullChgCap || '-'
                }} AH</span>
              </p>
            </div>
            <div class="battery-flex-item">
              <p> {{ $t('ProductDiagnosis.chargeDischarge') }} <span
                  class="custom-color">{{ batteryInfo?.BatCycles || '-' }}</span></p>
            </div>
            <div class="battery-flex-item">
              <p> {{ $t('ProductDiagnosis.soc') }} <span class="custom-color percentage-inline">{{
                  batteryInfo?.BatSoc || '-'
                }} %</span></p>
            </div>
            <div class="battery-flex-item">
              <p>{{ $t('ProductDiagnosis.remainingCapacity') }} <span
                  class="custom-color ">{{ batteryInfo?.BatRemCap || '-' }} AH</span></p>
            </div>
            <div class="battery-flex-item">
              <p> {{ $t('ProductDiagnosis.soh') }} <span class="custom-color">{{
                  batteryInfo?.BatSoh || '-'
                }} %</span>
              </p>
            </div>
          </div>
        </div>

      </template>
    </el-dialog>
  </div>

  <div class="diagnosis-box">
    <div class="content-wrapper">
      <img :src="dashboardImgUrl" width="40" height="40" alt="dashboard"/>
      <span class="motor-font"> {{ $t('ProductDiagnosis.display') }}</span>

      <span class="status" :class="{ online: isDisplayConnected && !isDisplayConnecting }" :style="displayStatusStyle">
         <template v-if="isMotorConnecting || isBatteryConnecting || isDisplayConnecting">
           {{ $t('ProductDiagnosis.connecting') }}<span class="blinking-dots"><span>.</span><span>.</span><span>.</span></span>
        </template>

        <template v-else-if="isDisplayConnected">
          {{ $t('ProductDiagnosis.online') }}
        </template>
        <template v-else>
          {{ $t('ProductDiagnosis.offline') }}
        </template>
      </span>

      <div class="tooltip-wrapper"
           v-if="!isDisplayConnected && !isDisplayConnecting && !isBatteryConnecting && !isMotorConnecting">
        <el-tooltip class="box-item" effect="light" placement="right">
          <template v-slot:content>
            <div class="font-setting-top">{{ $t('ProductDiagnosis.checkConnection') }}</div>
            <div class="font-setting-bottom">{{ $t('ProductDiagnosis.checkBatteryPower') }}</div>
          </template>
          <el-icon class="invert-icon">
            <QuestionIcon/>
          </el-icon>
        </el-tooltip>
      </div>

    </div>
    <div class="info">
      <table class="table-style">
        <tr>

          <td class="td-style">
            {{ $t('ProductDiagnosis.model') }}
            <span class="custom-color">{{ isDisplayConnected ? (displayTypeAndModelData?.model || '-') : '-' }}</span>

          </td>

          <td class="td-style">
            {{ $t('ProductDiagnosis.firmwareVersion') }}
            <span class="custom-color">{{ isDisplayConnected ? (displayInfo?.softwareversion || '-') : '-' }}</span>
          </td>
        </tr>
        <tr>
          <td class="td-style">
            {{ $t('ProductDiagnosis.serialNumber') }}
            <span class="custom-color">{{ isDisplayConnected ? (displayInfo?.snCode || '-') : '-' }}</span>

          </td>
          <td class="td-style">
            {{ $t('ProductDiagnosis.hardwareVersion') }}
            <span class="custom-color">{{ isDisplayConnected ? (displayInfo?.hardwareversion || '-') : '-' }}</span>

          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import QuestionIcon from "@/components/QuestionIcon.vue";

export default {
  components: {QuestionIcon},
  data() {
    return {
      batteryImgUrl: require("@/assets/battery.webp"),
      dashboardImgUrl: require("@/assets/dashboard.webp"),
      motorVisible: false,
      batteryVisible: false,

      motorModel: "",
      motorSerialNumber: "",
      motorSoftwareVersion: "",
      motorHardwareVersion: "",
      batteryStatus: "",
      batteryModel: "",
      batterySerialNumber: "",
      batteryHardwareVersion: "",
      batterySoftwareVersion: "",
      dashboardModel: "",
      dialogTitle: "",
    };
  },
  props: {
    motorInfo: Object,
    motorConfigData: Object,
    batteryInfo: Object,
    dashboardInfo: Object,
    displayInfo: Object,
    motorTypeAndModelData: Object,
    displayTypeAndModelData: Object,
    batteryDetailData: Object,

    displayConfigData: Object,
    displayInfoRealTimeData: Object,
  },
  computed: {
    getMotorTypeAndModelData() {
      const { isMotorConnected, isMotorConnecting, motorTypeAndModelData } = this;
      if (isMotorConnected && !isMotorConnecting && motorTypeAndModelData) {
        // 电机在线且数据存在，直接返回数据
        return motorTypeAndModelData;
      }
      // 电机未在线或数据不存在，返回带有默认值的对象
      return {
        motorType: null, // 或者其他适当的默认值，比如 'unknown'
        model: '-' // 默认值，表示模型未获取或电机未在线
      };
    },
    motorImgUrl() {
      if (this.getMotorTypeAndModelData.motorType === 'mid') {
        return require("@/assets/image/mid-motor.png");
      } else if (this.getMotorTypeAndModelData.motorType === 'hub') {
        return require("@/assets/image/hub-motor.png");
      } else {
        return require("@/assets/image/mid-motor.png");
      }
    },
    motorStatusStyle() {
      if (this.isMotorConnecting) {
        // 连接中状态，应用无边框和透明背景的样式
        return {
          backgroundColor: 'transparent',  // 或任何其他适合连接状态的颜色
          color: 'black',  // 保持文字颜色为黑色
          fontSize: '16px',
          padding: '5px 10px',
          border: 'none',  // 移除边框
          marginRight: '10px',
        };
      } else {
        // 在线和离线状态时应用正常样式
        return {
          backgroundColor: this.isMotorConnected ? "rgb(246,255,237)" : "rgb(245,245,245)",  // 在线时绿色背景，离线时灰色
          color: this.isMotorConnected ? "rgb(82,186,26)" : "black",  // 在线时绿色文字，离线时黑色
          fontSize: "12px",
          padding: "5px 10px",
          border: this.isMotorConnected ? "1px solid rgb(183,235,143)" : "1px solid #DDD",  // 在线时实心边框，离线时灰色边框
          marginRight: "10px",
        };
      }
    },

    displayStatusStyle() {
      if (this.isDisplayConnecting || this.isBatteryConnecting || this.isMotorConnecting) {
        return {
          backgroundColor: 'transparent',
          color: 'black',
          fontSize: '16px',
          padding: '5px 10px',
          border: 'none',
          marginRight: '10px',
        };
      } else {
        return {
          backgroundColor: this.isDisplayConnected ? "rgb(246,255,237)" : "rgb(245,245,245)",
          color: this.isDisplayConnected ? "rgb(82,186,26)" : "black",
          fontSize: "12px",
          padding: "5px 10px",
          border: this.isDisplayConnected ? "1px solid rgb(183,235,143)" : "1px solid #DDD",
          marginRight: "10px",
        };
      }
    },

    isBatteryInfoEmpty() {
      if (!this.batteryInfo) {
        return true; // 如果是，认为“电池信息为空”
      }
      return Object.keys(this.batteryInfo).length === 0;
    },

    batteryStatusStyle() {
      if (this.isBatteryConnecting || this.isMotorConnecting) {
        return {
          backgroundColor: 'transparent',
          color: 'black',
          fontSize: '16px',
          padding: '5px 10px',
          border: 'none',
          marginRight: '10px',
        };
      } else {
        return {
          backgroundColor: this.isBatteryInfoEmpty ? "rgb(245,245,245)" : "rgb(246,255,237)",
          color: this.isBatteryInfoEmpty ? "black" : "rgb(82,186,26)",
          fontSize: "12px",
          padding: "5px 10px",
          border: this.isBatteryInfoEmpty ? "1px solid #DDD" : "1px solid rgb(183,235,143)",
          marginRight: "10px",
        };
      }
    },
    isOnline() {
      // 在这里计算属性值并返回
      return this.calculateIsOnline(); // 示例计算方式
    },
    isMotorConnected() {
      return this.$store.state.isMotorConnected;
    },
    isDisplayConnected() {
      return this.$store.state.isDisplayConnected;
    },
    displayedLevels() {
      const levels = [];
      const maxLevel = this.motorConfigData?.maxLevel || 0;
      for (let i = 1; i <= maxLevel; i++) {
        levels.push({
          currentLevel: this.motorConfigData[`currentlevel${i}`] || '-',
          speedLevel: this.motorConfigData[`bikeSpeed${i}`] || '-',
        });
      }
      return levels;
    },
    isMotorConnecting() {
      return this.$store.state.isMotorConnecting;
    },
    isBatteryConnecting() {
      return this.$store.state.isBatteryConnecting;
    },
    isDisplayConnecting() {
      return this.$store.state.isDisplayConnecting;
    },

  },
  mixins: [dialogMixin],
  methods: {

    showDetails() {
      // 显示详情弹窗的逻辑
      this.motorVisible = true;
    },
    showBatteryDetails() {
      // 显示详情弹窗的逻辑
      this.batteryVisible = true;
    },
    calculateIsOnline() {
      // 根据逻辑计算 isOnline 的值
      return false;
    },
    deviceName(deviceCode) {
      const deviceName = {
        '1': this.$t('ProductDiagnosis.brakeSensor'),
        '2': this.$t('ProductDiagnosis.wheelSensor'),
        '3': this.$t('ProductDiagnosis.pedalSensor'),
        '4': this.$t('ProductDiagnosis.handlebarSensor'),
        '5': this.$t('ProductDiagnosis.torqueSensor'),
      };
      return deviceName[deviceCode] || this.$t('ProductDiagnosis.unknownDevice');
    },
  },
};
</script>

<style scoped>

.percentage-inline {
  white-space: nowrap; /* 防止在此元素内的文本换行 */
}

@keyframes blink {
  0%, 100% {
    opacity: 0;
    transform: translateY(-5px); /* 小跳动的起始和结束位置 */
  }
  50% {
    opacity: 1;
    transform: translateY(-15px); /* 较大的跳动 */
  }
}

.blinking-dots span {
  animation: blink 1.4s infinite;
  animation-fill-mode: both;
  font-size: 25px; /* 进一步增大点的尺寸 */
  line-height: 50px; /* 调整行高以适应更大的跳动 */
  display: inline-block;
  vertical-align: middle;
}

.blinking-dots span:nth-child(2) {
  animation-delay: .2s;
}

.blinking-dots span:nth-child(3) {
  animation-delay: .4s;
}

.external-devices-list {
  list-style: none; /* 移除列表项的标记 */
  padding-left: 0; /* 移除默认的内边距 */
  display: flex; /* 设置列表为flex布局，实现横向排列 */
}

.font-setting-top {
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 2px;
}

.font-setting-bottom {
  font-size: 14px;
  margin-bottom: 8px
}

.invert-icon {
  font-size: 14px;
}

.custom-color {
  color: #000000D9;
}

.td-spacing {
  width: 10px; /* 设置间隔宽度 */
}

.diagnosis-box {
  display: flex;
  /* 使用 Flex 布局 */
  flex-direction: column;
  /* 垂直方向排列内容 */
  align-items: flex-start;
  /* 水平居中对齐 */
  /* border: 1px solid #ddd;*/
  border: 1px solid rgba(221, 221, 221, 0.6); /* 更淡的边框颜色 */
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  width: 100%;
  /* 让每个方框占满容器宽度 */
  margin-right: 10px;
  margin-left: 10px;
}

.status {
  margin-left: 10px;
  font-size: 10px;
}

.online {
  color: green;
}

.info {
  margin-top: 20px;
  display: flex;
  /* 使用 Flex 布局 */
  flex-direction: column;
  /* 垂直方向排列内容 */
  align-items: flex-start;
  justify-content: flex-start;
}

.info-line {
  display: flex;
  /* 使用 Flex 布局 */
  justify-content: flex-start;
  margin-top: 10px;
  /* 两行之间添加一些间距 */
}

.info-item {
  margin-right: 115px;
  /* 调整项之间的水平间距 */
}

.details-button {
  margin-top: 10px;
  margin-right: 5px;
  background-color: #337ab7;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.details-button:hover {
  background-color: #286090;
}

.content-wrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding-left: 5px;
  width: 100%; /* 确保框的宽度固定 */
  height: 60px; /* 确保框的高度固定 */
}

.motor-font {
  font-size: 22px;
  /* 设置字体大小为图片的大小 */
  margin-left: 10px;
  /* 调整图片和字体之间的间距 */
}

.circle-question {
  display: inline-block;
  position: relative;
  width: 14px;
  height: 14px;
}

.circle {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #333;
}

.box-item {
  white-space: pre-line;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.section {
  margin-bottom: 20px;
}

.flex-container {
  color: rgba(0, 0, 0, 0.45); /* 设置文字颜色和透明度 */
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.flex-item {
  width: calc(33.33% - 10px);
  /* 让每个 flex-item 占据 33.33% 的宽度，并留有一些间距 */
  padding: 10px;
}

.flex-item p {
  margin: 0;
}

/* 自定义 <hr> 元素的样式 */
hr {
  border: none;
  /* 移除默认边框 */
  border-top: 1px solid #f0f0f0;
  /* 添加自定义的上边框，可以根据需要调整颜色 */
}

/* 使用柔和的 Sans-serif 字体 */
body {
  font-family: "Arial", sans-serif;
  color: rgba(153, 153, 153, 0.7);
  /* 使用淡灰色文本，降低透明度 */
}

/* 增加行高 */
p {
  line-height: 2.5;
}

.battery-flex-container {
  color: rgba(0, 0, 0, 0.45); /* 设置文字颜色和透明度 */
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  /* 允许换行 */
  margin-bottom: 0;
  /* 设置行间距 */
}

.battery-flex-item p {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.battery-flex-item {
  flex: 0 0 calc(33.33% - 10px);
  /* 让每个参数占据 33.33% 的宽度，并设置右边距 */
  margin-right: 10px;
  margin-bottom: 0;
}

/* 清除每行最后一个参数的右边距 */
.battery-flex-item:nth-child(3n) {
  margin-right: 0;
}

.tooltip-wrapper {
  margin-left: 0;
}

.td-style span:first-child {
  margin-right: 5px; /* 键和值之间的间距 */
}

.td-style {
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  min-width: 150px; /* 设置一个最小宽度 */
  padding: 4px; /* 减小内边距 */
}

.td-button {
  width: 10%;
  text-align: right;
  white-space: nowrap;
}

/* 样式用于将超链接样式成按钮，可以根据需要进行样式自定义 */
.details-link {
  display: inline-block;
  padding: 8px 16px;
  color: rgba(0, 154, 68);
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
}

.table-style {
  font-size: 14px;
  width: 100%; /* 确保表格占据整个容器的宽度 */
  table-layout: fixed; /* 添加固定表格布局 */

}

.no-border-button {
  border: none;
  background: none;
}
</style>
